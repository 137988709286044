import React, { useState, useEffect } from "react";
import { CountDownHourly, CountDownDaily } from '@nilevia/count-down-timer-react'
import '@nilevia/count-down-timer-react/dist/index.css'

import logo from "./logo.svg";
import "./App.css";
import "./mobile.css";
import Particles, { DivMode } from "react-particles-js";
import Header from "./Components/NavBar/Header";
import Win from "./Components/WIndow/Window";
import Landing from "./Components/WIndow/Landing"
import lo from "../src/images/b4.png";
import meta from "../src/images/meta.png";

import data from "./data.json";
import i1 from "../src/images/road-map_01.jpg";
import i2 from "../src/images/road-map_02.jpg";
import i3 from "../src/images/road-map_03.jpg";
import i4 from "../src/images/road-map_04.jpg";
import i5 from "../src/images/road-map_05.jpg";
import i6 from "../src/images/road-map_06.jpg";
import i7 from "../src/images/road-map_07.jpg";
import i8 from "../src/images/road-map_08.jpg";
import i9 from "../src/images/road-map_09.jpg";
import i10 from "../src/images/road-map_10.jpg";
import i11 from "../src/images/road-map_11.jpg";
import i12 from "../src/images/road-map_12.jpg";
import i13 from "../src/images/road-map_13.jpg";
import i14 from "../src/images/road-map_14.jpg";
import i15 from "../src/images/road-map_15.jpg";
import i16 from "../src/images/road-map_16.jpg";
import i17 from "../src/images/road-map_17.jpg";
import i18 from "../src/images/road-map_18.jpg";
import i19 from "../src/images/road-map_19.jpg";
import i20 from "../src/images/road-map_20.jpg";
import i21 from "../src/images/road-map_21.jpg";
import i22 from "../src/images/road-map_22.jpg";
import i23 from "../src/images/road-map_23.jpg";
import i24 from "../src/images/road-map_24.jpg";
import i25 from "../src/images/road-map_25.jpg";
import i26 from "../src/images/road-map_26.jpg";
import i27 from "../src/images/road-map_27.jpg";
import i28 from "../src/images/road-map_28.jpg";
import i29 from "../src/images/road-map_29.jpg";
import i30 from "../src/images/road-map_30.jpg";
import i31 from "../src/images/road-map_31.jpg";
import i32 from "../src/images/road-map_32.jpg";
import i33 from "../src/images/road-map_33.jpg";
import i34 from "../src/images/road-map_34.jpg";
import i35 from "../src/images/road-map_35.jpg";
import i36 from "../src/images/road-map_36.jpg";
import i37 from "../src/images/road-map_37.jpg";
import i38 from "../src/images/road-map_38.jpg";
import i39 from "../src/images/road-map_39.jpg";
import i40 from "../src/images/road-map_40.jpg";
import i41 from "../src/images/road-map_41.jpg";
import i42 from "../src/images/road-map_42.jpg";
import i43 from "../src/images/road-map_43.jpg";
import i44 from "../src/images/road-map_44.jpg";
import i45 from "../src/images/road-map_45.jpg";
import i46 from "../src/images/road-map_46.jpg";
import i47 from "../src/images/road-map_47.jpg";
import i48 from "../src/images/road-map_48.jpg";
import i49 from "../src/images/road-map_49.jpg";
import i50 from "../src/images/road-map_50.jpg";
import i51 from "../src/images/road-map_51.jpg";
import i52 from "../src/images/road-map_52.jpg";
import i53 from "../src/images/road-map_53.jpg";
import i54 from "../src/images/road-map_54.jpg";
import i55 from "../src/images/road-map_55.jpg";
import i56 from "../src/images/road-map_56.jpg";
import i57 from "../src/images/road-map_57.jpg";
import i58 from "../src/images/road-map_58.jpg";
import i59 from "../src/images/road-map_59.jpg";
import i60 from "../src/images/road-map_60.jpg";
import i61 from "../src/images/road-map_61.jpg";
import i62 from "../src/images/road-map_62.jpg";
import i63 from "../src/images/road-map_63.jpg";
import i64 from "../src/images/road-map_64.jpg";
import i65 from "../src/images/road-map_65.jpg";
import i66 from "../src/images/road-map_66.jpg";
import i67 from "../src/images/road-map_67.jpg";
import i68 from "../src/images/road-map_68.jpg";
import i69 from "../src/images/road-map_69.jpg";
import i70 from "../src/images/road-map_70.jpg";
import i71 from "../src/images/road-map_71.jpg";
import i72 from "../src/images/road-map_72.jpg";
import i73 from "../src/images/road-map_73.jpg";
import i74 from "../src/images/road-map_74.jpg";
import i75 from "../src/images/road-map_75.jpg";
import i76 from "../src/images/road-map_76.jpg";
import i77 from "../src/images/road-map_77.jpg";
import i78 from "../src/images/road-map_78.jpg";
import i79 from "../src/images/road-map_79.jpg";
import i80 from "../src/images/road-map_80.jpg";
import i81 from "../src/images/road-map_81.jpg";
import i82 from "../src/images/road-map_82.jpg";
import i83 from "../src/images/road-map_83.jpg";
import i84 from "../src/images/road-map_84.jpg";
import i85 from "../src/images/road-map_85.jpg";
import i86 from "../src/images/road-map_86.jpg";
import i87 from "../src/images/road-map_87.jpg";
import i88 from "../src/images/road-map_88.jpg";
import i89 from "../src/images/road-map_89.jpg";
import i90 from "../src/images/road-map_90.jpg";
import i91 from "../src/images/road-map_91.jpg";
import i92 from "../src/images/road-map_92.jpg";
import i93 from "../src/images/road-map_93.jpg";
import i94 from "../src/images/road-map_94.jpg";
import i95 from "../src/images/road-map_95.jpg";
import i96 from "../src/images/road-map_96.jpg";
import { FacebookShareButton } from "react-share";
import MetaTags from 'react-meta-tags';
import { Helmet } from "react-helmet";
import 'moment-timezone';

function App() {
  const daty = new Date();
  const dateLimit = new Date("2020-12-25T23:59:59+01:00")
  //tomorrow.setDate(tomorrow.getDate() + 1)
  //daty.getDate()
  //console.log("Anio " + daty.getDate() + ' farany ' + endDat);
  /*const mois = daty.getMonth() + 1;
  const datyFeno = daty.getDate() + '/' + mois + '/' + daty.getFullYear();
  console.log("Daty feno " + daty.getDate() + '/' + mois + '/' + daty.getFullYear());*/
  console.log("String" + dateLimit);

  //piece of code which run based on given condition
  useEffect(() => {
    const unsubscribe = () => {
      if (
        daty === dateLimit
      ) {
        console.log("mitovy");
      } else {
        console.log("tsy mitovy");
      }
    };

    return () => {
      //cleanup operation
      unsubscribe();
    };
  }, []);


  return (
    <div className="Apps">
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://avent.millionnaire-visionnaire.com/" />
        <meta property="og:title" content="Calendrier de l'Avent de la Liberté financière" />
        <meta property="og:description" content="test" />
        <meta property="og:image" content={meta} />
        <meta http-equiv="cache-control" content="no-cache" />
      </Helmet>
      <div className="Header">

      </div>
      <div className="">
        <div className="izy_rehetra ">
          <div className="">
            <div className="col Table_01">
              <div className="road-map-01">

                <img src={i1} width="1519" height="384" alt="" />
              </div>
              <div className="road-map-02">

                <a target="_blank" href="https://www.millionnaire-visionnaire.com/cdv-pdv-offre-essai-nov-2020/">
                  <img className="img-fluid logoCDV" src={lo} width="auto" height="auto" alt="" /></a>
                <img src={i2} width="944" height="508" alt="" />
              </div>
              <div className="road-map-03 ">
                <Win
                  className={`done1 tuile`}
                  title={data.day1.title}
                  id={data.day1.id}
                  url={data.day1.url}
                  pdfUrl={data.day1.pdfurl}
                />
              </div>
              <div className="road-map-04">
                <img src={i4} width="130" height="2510" alt="" />
              </div>
              <div className="road-map-05">
                <img src={i5} width="445" height="225" alt="" />
              </div>
              <div className="road-map-06">
                <img src={i6} width="241" height="401" alt="" />
              </div>
              <div className="road-map-07">
                <Win
                  className={`done3 tuile`}
                  title={data.day3.title}
                  id={data.day3.id}
                  url={data.day3.url}
                  pdfUrl={data.day3.pdfurl}
                />
              </div>
              <div className="road-map-08">
                <img src={i8} width="257" height="619" alt="" />
              </div>
              <div className="road-map-09">
                <Win
                  className={`done2 tuile`}
                  title={data.day2.title}
                  id={data.day2.id}
                  url={data.day2.url}
                  pdfUrl={data.day2.pdfurl}
                />
              </div>
              <div className="road-map-10">
                <img src={i10} width="446" height="115" alt="" />
              </div>
              <div className="road-map-11">
                <img src={i11} width="445" height="330" alt="" />
              </div>
              <div className="road-map-12">
                <img src={i12} width="149" height="1074" alt="" />
              </div>
              <div className="road-map-13">
                <Win
                  className={`done4 tuile`}
                  title={data.day4.title}
                  id={data.day4.id}
                  url={data.day4.url}
                  pdfUrl={data.day4.pdfurl}
                />
              </div>
              <div className="road-map-14">
                <img src={i14} width="93" height="698" alt="" />
              </div>
              <div className="road-map-15">
                <img src={i15} width="248" height="480" alt="" />
              </div>
              <div className="road-map-16">
                <Win
                  className={`done5 tuile`}
                  title={data.day5.title}
                  id={data.day5.id}
                  url={data.day5.url}
                  pdfUrl={data.day5.pdfurl}
                />
              </div>
              <div className="road-map-17">
                <img src={i17} width="8" height="1383" alt="" />
              </div>
              <div className="road-map-18">
                <img src={i18} width="445" height="412" alt="" />
              </div>
              <div className="road-map-19">
                <img src={i19} width="446" height="194" alt="" />
              </div>
              <div className="road-map-20">
                <img src={i20} width="75" height="376" alt="" />
              </div>
              <div className="road-map-21">
                <Win
                  className={`done7 tuile`}
                  title={data.day7.title}
                  id={data.day7.id}
                  url={data.day7.url}
                  pdfUrl={data.day7.pdfurl}
                />
              </div>
              <div className="road-map-22">
                <img src={i22} width="263" height="587" alt="" />
              </div>
              <div className="road-map-23">
                <Win
                  className={`done6 tuile`}
                  title={data.day6.title}
                  id={data.day6.id}
                  url={data.day6.url}
                  pdfUrl={data.day6.pdfurl}
                />
              </div>
              <div className="road-map-24">
                <img src={i24} width="3" height="903" alt="" />
              </div>
              <div className="road-map-25">
                <img src={i25} width="445" height="90" alt="" />
              </div>
              <div className="road-map-26">
                <img src={i26} width="446" height="301" alt="" />
              </div>
              <div className="road-map-27">
                <img src={i27} width="60" height="5491" alt="" />
              </div>
              <div className="road-map-28">
                <Win
                  className={`done8 tuile`}
                  title={data.day8.title}
                  id={data.day8.id}
                  url={data.day8.url}
                  pdfUrl={data.day8.pdfurl}
                />
              </div>
              <div className="road-map-29">
                <img src={i29} width="163" height="211" alt="" />
              </div>
              <div className="road-map-30">
                <img src={i30} width="88" height="415" alt="" />
              </div>
              <div className="road-map-31">
                <Win
                  className={`done9 tuile`}
                  title={data.day9.title}
                  id={data.day9.id}
                  url={data.day9.url}
                  pdfUrl={data.day9.pdfurl}
                />
              </div>
              <div className="road-map-32">
                <img src={i32} width="339" height="316" alt="" />
              </div>
              <div className="road-map-33">
                <img src={i33} width="446" height="340" alt="" />
              </div>
              <div className="road-map-34">
                <img src={i34} width="445" height="30" alt="" />
              </div>
              <div className="road-map-35">
                <img src={i35} width="439" height="99" alt="" />
              </div>
              <div className="road-map-36">
                <Win
                  className={`done10 tuile`}
                  title={data.day10.title}
                  id={data.day10.id}
                  url={data.day10.url}
                  pdfUrl={data.day10.pdfurl}
                />
              </div>
              <div className="road-map-37">
                <img src={i37} width="41" height="4964" alt="" />
              </div>
              <div className="road-map-38">
                <img src={i38} width="331" height="387" alt="" />
              </div>
              <div className="road-map-39">
                <Win
                  className={`done11 tuile`}
                  title={data.day11.title}
                  id={data.day11.id}
                  url={data.day11.url}
                  pdfUrl={data.day11.pdfurl}
                />
              </div>
              <div className="road-map-40">
                <img src={i40} width="197" height="415" alt="" />
              </div>
              <div className="road-map-41">
                <img src={i41} width="445" height="228" alt="" />
              </div>
              <div className="road-map-42">
                <img src={i42} width="445" height="101" alt="" />
              </div>
              <div className="road-map-43">
                <img src={i43} width="14" height="4478" alt="" />
              </div>
              <div className="road-map-44">
                <Win
                  className={`done12 tuile`}
                  title={data.day12.title}
                  id={data.day12.id}
                  url={data.day12.url}
                  pdfUrl={data.day12.pdfurl}
                />
              </div>
              <div className="road-map-45">
                <img src={i45} width="317" height="28" alt="" />
              </div>
              <div className="road-map-46">
                <img src={i46} width="298" height="353" alt="" />
              </div>
              <div className="road-map-47">
                <Win
                  className={`done13 tuile`}
                  title={data.day13.title}
                  id={data.day13.id}
                  url={data.day13.url}
                  pdfUrl={data.day13.pdfurl}
                />
              </div>
              <div className="road-map-48">
                <img src={i48} width="216" height="740" alt="" />
              </div>
              <div className="road-map-49">
                <img src={i49} width="445" height="95" alt="" />
              </div>
              <div className="road-map-50">
                <img src={i50} width="445" height="67" alt="" />
              </div>
              <div className="road-map-51">
                <img src={i51} width="390" height="384" alt="" />
              </div>
              <div className="road-map-52">
                <Win
                  className={`done14 tuile`}
                  title={data.day14.title}
                  id={data.day14.id}
                  url={data.day14.url}
                  pdfUrl={data.day14.pdfurl}
                />
              </div>
              <div className="road-map-53">
                <img src={i53} width="353" height="387" alt="" />
              </div>
              <div className="road-map-54">
                <img src={i54} width="445" height="98" alt="" />
              </div>
              <div className="road-map-55">
                <img src={i55} width="39" height="507" alt="" />
              </div>
              <div className="road-map-56">
                <Win
                  className={`done15 tuile`}
                  title={data.day15.title}
                  id={data.day15.id}
                  url={data.day15.url}
                  pdfUrl={data.day15.pdfurl}
                />
              </div>
              <div className="road-map-57">
                <img src={i57} width="350" height="3" alt="" />
              </div>
              <div className="road-map-58">
                <img src={i58} width="277" height="972" alt="" />
              </div>
              <div className="road-map-59">
                <Win
                  className={`done16 tuile`}
                  title={data.day16.title}
                  id={data.day16.id}
                  url={data.day16.url}
                  pdfUrl={data.day16.pdfurl}
                />
              </div>
              <div className="road-map-60">
                <img src={i60} width="196" height="394" alt="" />
              </div>
              <div className="road-map-61">
                <img src={i61} width="446" height="221" alt="" />
              </div>
              <div className="road-map-62">
                <img src={i62} width="446" height="108" alt="" />
              </div>
              <div className="road-map-63">
                <img src={i63} width="119" height="578" alt="" />
              </div>
              <div className="road-map-64">
                <Win
                  className={`done17 tuile`}
                  title={data.day17.title}
                  id={data.day17.id}
                  url={data.day17.url}
                  pdfUrl={data.day17.pdfurl}
                />
              </div>
              <div className="road-map-65">
                <img src={i65} width="78" height="753" alt="" />
              </div>
              <div className="road-map-66">
                <img src={i66} width="24" height="1285" alt="" />
              </div>
              <div className="road-map-67">
                <Win
                  className={`done18 tuile`}
                  title={data.day18.title}
                  id={data.day18.id}
                  url={data.day18.url}
                  pdfUrl={data.day18.pdfurl}
                />
              </div>
              <div className="road-map-68">
                <img src={i68} width="16" height="468" alt="" />
              </div>
              <div className="road-map-69">
                <img src={i69} width="445" height="467" alt="" />
              </div>
              <div className="road-map-70">
                <img src={i70} width="445" height="182" alt="" />
              </div>
              <div className="road-map-71">
                <img src={i71} width="316" height="817" alt="" />
              </div>
              <div className="road-map-72">
                <Win
                  className={`done19 tuile`}
                  title={data.day19.title}
                  id={data.day19.id}
                  url={data.day19.url}
                  pdfUrl={data.day19.pdfurl}
                />
              </div>
              <div className="road-map-73">
                <img src={i73} width="96" height="440" alt="" />
              </div>
              <div className="road-map-74">
                <img src={i74} width="40" height="766" alt="" />
              </div>
              <div className="road-map-75">
                <Win
                  className={`done20 tuile`}
                  title={data.day20.title}
                  id={data.day20.id}
                  url={data.day20.url}
                  pdfUrl={data.day20.pdfurl}
                />
              </div>
              <div className="road-map-76">
                <img src={i76} width="38" height="2563" alt="" />
              </div>
              <div className="road-map-77">
                <img src={i77} width="445" height="154" alt="" />
              </div>
              <div className="road-map-78">
                <img src={i78} width="72" height="377" alt="" />
              </div>
              <div className="road-map-79">
                <Win
                  className={`done21 tuile`}
                  title={data.day21.title}
                  id={data.day21.id}
                  url={data.day21.url}
                  pdfUrl={data.day21.pdfurl}
                />
              </div>
              <div className="road-map-80">
                <img src={i80} width="24" height="501" alt="" />
              </div>
              <div className="road-map-81">
                <img src={i81} width="445" height="480" alt="" />
              </div>
              <div className="road-map-82">
                <img src={i82} width="445" height="91" alt="" />
              </div>
              <div className="road-map-83">
                <img src={i83} width="15" height="1921" alt="" />
              </div>
              <div className="road-map-84">
                <Win
                  className={`done22 tuile`}
                  title={data.day22.title}
                  id={data.day22.id}
                  url={data.day22.url}
                  pdfUrl={data.day22.pdfurl}
                />
              </div>
              <div className="road-map-85">
                <img src={i85} width="396" height="124" alt="" />
              </div>
              <div className="road-map-86">
                <img src={i86} width="324" height="823" alt="" />
              </div>
              <div className="road-map-87">
                <Win
                  className={`done23 tuile`}
                  title={data.day23.title}
                  id={data.day23.id}
                  url={data.day23.url}
                  pdfUrl={data.day23.pdfurl}
                />
              </div>
              <div className="road-map-88">
                <img src={i88} width="136" height="1797" alt="" />
              </div>
              <div className="road-map-89">
                <img src={i89} width="446" height="200" alt="" />
              </div>
              <div className="road-map-90">
                <img src={i90} width="445" height="537" alt="" />
              </div>
              <div className="road-map-91">
                <Win
                  className={`done24 tuile`}
                  title={data.day24.title}
                  id={data.day24.id}
                  url={data.day24.url}
                  pdfUrl={data.day24.pdfurl}
                />
              </div>
              <div className="road-map-92">
                <img src={i92} width="446" height="1148" alt="" />
              </div>
              <div className="road-map-93">
                <img src={i93} width="57" height="974" alt="" />
              </div>
              <div className="road-map-94">
                <Win
                  className={`done25 tuile`}
                  title={data.day25.title}
                  id={data.day25.id}
                  url={data.day25.url}
                  pdfUrl={data.day25.pdfurl}
                />
              </div>
              <div className="road-map-95">
                <img src={i95} width="267" height="974" alt="" />
              </div>
              <div className="road-map-96">
                <img src={i96} width="445" height="688" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className={`${daty <= dateLimit ? "time" : " d-none"} `}  >
          <p className="time__first">Fin du calendrier de l'Avent et de l'offre d'essai du CDV dans
          <CountDownDaily className="count"
              endDate={"2020-12-25T23:59:59+01:00"}
              dayAffix="j"
              hourAffix="h"
              minutesAffix="min"
              secondAffix="sec"
            /></p>
          <a className="bandeau" target="_blank" href="https://www.millionnaire-visionnaire.com/cdv-pdv-offre-essai-nov-2020/"><p className="time__second"> Cliquez ICI pour tester le Cercle des Visionnaires pour 7€ pendant 7 jours !</p></a>
        </div>

        <div className={`${daty >= dateLimit ? "finished" : " d-none"}`}>
          <h1 className="finished__text">
            Le Calendrier de l'Avent de la liberté financière est terminé... A l'année prochaine pour de nouvelles aventures !
          </h1>

        </div>

      </div>

      <Particles

        params={{
          particles: {
            number: {
              value: 160,
              density: {
                enable: false,
                value_area: 800,
              },
            },
            size: {
              value: 10,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false,
              },
            },
            move: {
              direction: "bottom",
              out_mode: "out",
            },
            line_linked: {
              enable: false,
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "bubble",
              },
              onclick: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: { opacity: 0.5 },
              },
              bubble: {
                distance: 400,
                size: 4,
                duration: 0.3,
                opacity: 1,
                speed: 3,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
        }}
      />
    </div >
  );
}

export default App;
