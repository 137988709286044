import React, { useState, useEffect } from 'react'
import "./Header.css"
function Header() {
    const [open, setOpen] = useState(true);
    const [scroll, setScroll] = useState(false);

    const body = document.querySelector("body");

    function bodystyle(e) {
        document.body.classList.add('disabled');

    }

    function removestyle(e) {
        document.body.classList.remove('disabled');

    }

    useEffect(() => {
        const navbar = document.querySelector(".navbar26");
        window.addEventListener("scroll", () => {
            // setScroll(window.scrollY > 20);
            window.scrollY > 20 ? navbar.classList.add("sticky") : navbar.classList.remove("sticky");
        });
    }, [])

    return (
        <div>
            <nav className={open ? "navbar26" : "navbar26 show"}  >
                <div className="content">
                    <div className="logo">
                        <a href="#"></a>
                    </div>
                    <ul className="menu-list">
                        <div className={open ? "icon cancel-btn hide " : "icon cancel-btn"} onClick={() => { setOpen(!open); removestyle() }}>
                            <i className="fas fa-times"></i>
                        </div>
                        <li><a href="#">Contact</a></li>
                        <span>Flow corporation 2020</span>
                    </ul>
                    <div className={open ? "icon menu-btn " : "icon menu-btn hide"} onClick={() => { setOpen(!open); bodystyle() }}>
                        <i className="fas fa-bars"></i>
                    </div>
                </div>
            </nav>

        </div >
    )
}

export default Header
