import React, { useState, useEffect } from 'react'
import './Landing.css'
import './Form.css'

import Form from './Form'
function Landing() {
    const [open, setOpen] = useState(true);
    const blurBack = () => {
        var apps = document.querySelector("body");
        apps.classList.toggle('disabled');

        /* var appss = document.querySelector(".newLayout-03");
         appss.classList.toggle('play');*/

    }
    useEffect(() => {

        blurBack();
    });
    return (
        <div>
            <div className={open ? "popupLanding active shadow" : "popupLanding "}>
                <div className="text-right"> <div type="button" className="btn" onClick={() => { setOpen(false) }}>X</div></div>
                <div className="inside_landing text-center d-flex">

                    <form method="POST" action="https://millionnaire-visionnaire.activehosted.com/proc.php" id="_form_114_" className="_form _form_114 _inline-form  _dark" novalidate>
                        <input type="hidden" name="u" value="114" />
                        <input type="hidden" name="f" value="114" />
                        <input type="hidden" name="s" />
                        <input type="hidden" name="c" value="0" />
                        <input type="hidden" name="m" value="0" />
                        <input type="hidden" name="act" value="sub" />
                        <input type="hidden" name="v" value="2" />
                        <div className="_form-content">
                            <div className="_form_element _x03547279 _full_width _clear" >
                                <div className="_form-title">
                                    Sur quel email souhaitez-vous recevoir votre Calendrier de L&#039;avent
        </div>
                            </div>
                            <div className="_form_element _x05114353 _full_width _clear" >
                                <div className="_html-code">
                                    <p>
                                        Ajoutez un message descriptif indiquant au visiteur ce à quoi il s'inscrit.
          </p>
                                </div>
                            </div>
                            <div className="_form_element _x53220100 _full_width " >
                                <label className="_form-label">
                                    Prénom*
        </label>
                                <div className="_field-wrapper">
                                    <input className="form-control" type="text" name="firstname" placeholder="Saisissez votre prénom" required />
                                </div>
                            </div>
                            <div className="_form_element _x48255208 _full_width " >
                                <label className="_form-label">
                                    Courriel*
        </label>
                                <div className="_field-wrapper">
                                    <input className="form-control" type="text" name="email" placeholder="Saisissez votre e-mail" required />
                                </div>
                            </div>
                            <div className="_form_element _x36813832 _full_width " >
                                <label className="_form-label">
                                    Téléphone
        </label>
                                <div className="_field-wrapper">
                                    <input className="form-control" type="number" name="phone" placeholder="Saisissez votre numéro de téléphone" />
                                </div>
                            </div>
                            <div className="_button-wrapper _full_width">
                                <button id="_form_114_submit" className="_submit " type="submit" >
                                    Soumettre
        </button>

                            </div>
                            <div className="_clear-element">
                            </div>
                        </div>
                        <div className="_form-thank-you" style={{ display: "none" }}>
                        </div>
                        <div className="_form-branding d-none">
                            <div className="_marketing-by">
                                Marketing par
      </div>
                            <a href="https://www.activecampaign.com/?utm_medium=referral&utm_campaign=acforms" className="_logo"></a>
                        </div>
                    </form>

                </div>


                <div className="text-center pt-2 d-none">
                    <a className="link btn text-white shadow" target="_blank" href="https://www.millionnaire-visionnaire.com/formulaire-inscription-client-cdv"> Rejoindre le Cercle des Visionnaires</a>
                </div>
            </div>

        </div>
    )
}

export default Landing
