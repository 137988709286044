import React, { useState, useEffect } from 'react'
import "./Window.css"
import ReactPlayer from 'react-player'
import { ReactComponent as IconPlay } from './iconPlay.svg';
import ShareLink from 'react-facebook-share-link'
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, MailruShareButton, MailruIcon, EmailShareButton, EmailIcon } from "react-share";
import $ from 'jquery';

function Window({ id, url, title, className, pdfUrl }) {

    const [clicked, setClicked] = useState(true);
    const dates = new Date();
    const blurBack = () => {
        var apps = document.querySelector("body");
        apps.classList.toggle('disabled');

        /* var appss = document.querySelector(".newLayout-03");
         appss.classList.toggle('play');*/

    }


    $('.alefa').on('click', function () {
        blurBack();
        setClicked(!clicked);
        console.log("fsef");
    });

    $('.modal').on('hidden.bs.modal', function () {
        setClicked(!clicked);
        console.log("mihidy");
    })

    return (
        <div>
            <div className={className} data-toggle="modal" data-target={"#exampleModalCenter" + id} onClick={() => { setClicked(!clicked) }}>

                <div className='d-none'>
                    <span className={id == dates.getDate() ? "text" : " notToday"}>{id}</span>
                    <div><IconPlay className={id == dates.getDate() ? "img sary" : " sary"} /></div>
                </div>


            </div>

            <div class="modal fade" id={"exampleModalCenter" + id} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">

                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => { setClicked(!clicked) }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <h5 className="text-center mx-xl-5  mb-xs-2 mb-2">{id > dates.getDate() ? 'Patience Patience... 😉' : `${title}`}</h5>
                        <div class="modal-body">
                            {id <= dates.getDate() ?
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player'
                                        url={url}
                                        width='100%'
                                        height='100%'
                                        background-color="#ccc"
                                        playing={clicked ? false : true}
                                        controls={true}
                                    /></div> :
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player'
                                        url='https://flow-corporation.wistia.com/medias/t2qprwmnko'
                                        width='100%'
                                        height='100%'
                                        background-color="#ccc"
                                        playing={clicked ? false : true}
                                        controls={false}
                                    />
                                </div>}
                        </div>
                        <div className=" text-center pt-2">
                            <a className="link btn text-white shadow" target="_blank" href="https://www.millionnaire-visionnaire.com/cdv-pdv-offre-essai-nov-2020/"> Rejoindre le Cercle des Visionnaires</a>

                        </div>

                        <div className="text-center pt-2 social px-5">
                            <div className="d-none d-sm-block">
                                <FacebookShareButton url="https://avent.millionnaire-visionnaire.com/" ><FacebookIcon size={40} round={true} /> </FacebookShareButton>
                                <LinkedinShareButton url="https://avent.millionnaire-visionnaire.com/" ><LinkedinIcon size={40} round={true} /></LinkedinShareButton>
                                <EmailShareButton url="https://avent.millionnaire-visionnaire.com/"><EmailIcon size={40} round={true} /></EmailShareButton>
                            </div>

                            <div className="d-block d-sm-none">
                                <FacebookShareButton url="https://avent.millionnaire-visionnaire.com/" ><FacebookIcon size={70} round={true} /> </FacebookShareButton>
                                <LinkedinShareButton url="https://avent.millionnaire-visionnaire.com/" ><LinkedinIcon size={70} round={true} /></LinkedinShareButton>
                                <EmailShareButton url="https://avent.millionnaire-visionnaire.com/"><EmailIcon size={70} round={true} /></EmailShareButton>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Window
